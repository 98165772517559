<template>
  <div class="cupShow" ref="cupShow" id="cupshow">
  </div>
  <template v-if="params.projectType == 0">
    <cupShare :params="params"></cupShare>
  </template>
  <template v-else-if="params.projectType == 1">
    <reticuleShare :params="params"></reticuleShare>
  </template>

</template>

<script lang="ts">
import {ref, onMounted, computed} from 'vue'
import {MutationTypes, useStore} from '@/store'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {getProject, getProjectByLine, getTemplateThumbByLine} from '@/api/project'
import {message} from 'ant-design-vue'
import {getQueryObject} from '@/utils/utils'
import axios from 'axios'
import CupShare from '@/views/System/share/cupShare.vue'
import reticuleShare from '@/views/System/share/reticuleShare.vue'

export default ({
  name: 'cupShow',
  components:{
    reticuleShare,
    CupShare
  },
  setup() {

    let params  = ref({})
    onMounted(async () => {
      params.value = getQueryObject(window.location.href)
    })

    return{
      params
    }
  },
})
</script>

<style lang="scss" scoped>
.pptist-screen {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #111;
}

.slide-list {
  background: #1d1d1d;
  position: relative;
  width: 100%;
  height: 100%;
}

.slide-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.current {
    z-index: 2;
  }

  &.hide {
    opacity: 0;
  }

  &.turning-mode-no {
    &.before {
      transform: translateY(-100%);
    }

    &.after {
      transform: translateY(100%);
    }
  }

  &.turning-mode-fade {
    transition: opacity .75s;

    &.before {
      pointer-events: none;
      opacity: 0;
    }

    &.after {
      pointer-events: none;
      opacity: 0;
    }
  }

  &.turning-mode-slideX {
    transition: transform .35s;

    &.before {
      transform: translateX(-100%);
    }

    &.after {
      transform: translateX(100%);
    }
  }

  &.turning-mode-slideY {
    transition: transform .35s;

    &.before {
      transform: translateY(-100%);
    }

    &.after {
      transform: translateY(100%);
    }
  }
}

.slide-content {
  //background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools {
  position: fixed;
  bottom: 8px;
  left: 8px;
  font-size: 25px;
  color: #666;
  z-index: 10;
  cursor: pointer;
}

.tool-btn {
  opacity: .35;

  &:hover {
    opacity: .9;
  }

  & + .tool-btn {
    margin-left: 8px;
  }
}

.page-number {
  position: fixed;
  bottom: 8px;
  right: 8px;
  padding: 8px 12px;
  color: #666;
  background-color: #eee;
  border-radius: $borderRadius;
  z-index: 10;
  cursor: pointer;
}
</style>