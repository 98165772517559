<template>
  <div class="cupShow" ref="cupShow" id="cupshow">
  </div>
</template>

<script lang="ts">
import {ref, onMounted, computed} from 'vue'
import {MutationTypes, useStore} from '@/store'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {getQueryObject} from '@/utils/utils'
import axios from 'axios'

export default ({
  name: 'cupShare',
  props:{
    params:{
      type:Object
    }
  },
  setup(props:any) {

    const store = useStore()

    const cup = ref(null)
    const cupShow = ref(null)
    var htmlString = ref('')

    let imgUrl:any

    var container: any
    var camera: any, scene: any, renderer: any
    var papercup = {upSize: 36.132194422939364, downSize: 27.082243041635262, height: 87.40582038417021}
    var mPaperMaterial: any, paperCube: any
    var controls: any
    var loader: any = new THREE.TextureLoader()
    var lightHolder: any


    //记录每次动画执行结束的时间
    var lastTime = Date.now();
    //当前执行时间
    var nowTime = 0;
    var diffTime = 60;

    //初始化匀速转动
    var cshRotation = 0.01;
    var targetRotationX = 0, targetRotationY = 0;
    var targetRotationXOnMouseDown = 0, targetRotationYOnMouseDown = 0;
    var mouseX, mouseY = 0;
    var mouseXOnMouseDown:any, mouseYOnMouseDown = 0;
    var windowHalfX = window.innerWidth / 2;
    var windowHalfY = window.innerHeight / 2;
    onMounted(async () => {
      await getImage()
       main()
    })

    // 获取照片
    const getImage= async ()=>{
      await axios.get(`apis/filesup/templateThumb/${props.params.projectGUID}_${props.params.pageIndex}.bmg`).then(res =>{
        // console.log(res)
        imgUrl=res.data.replace(/[\r\n]/g,"");
      })
      // do{
      //   let data = {
      //     "projectGUID": params.projectGUID,
      //     "pageIndex":params.pageIndex,
      //     "startLine": currentLine
      //   }
      //   await getTemplateThumbByLine(data).then(res =>{
      //     // console.log(res)
      //     let data = res.data
      //     if (res.httpCode == 200) {
      //       if (res.data.readComplete == 1) {
      //         readComplete = true;
      //       }
      //
      //       currentLine += res.data.lineCount;
      //       // scriptContent += res.data.script;
      //       if (scriptContent == "") {
      //         scriptContent = res.data.script;
      //       } else {
      //         scriptContent += res.data.script;
      //       }
      //     } else {
      //       readComplete = true;
      //     }
      //
      //
      //   }).catch(err =>{
      //     // message.error(err)
      //   })
      // }while (!readComplete)
      // console.log('scriptContent',scriptContent)
      // imgUrl = scriptContent
      // store.commit(MutationTypes.SET_IS_EDIT_PROJECT, true)
      // store.commit(MutationTypes.SET_ACTIVE_PROJECT_ID,data.id)
      // store.commit(MutationTypes.SET_ACTIVE_PROJECT_NAME,data.projectName)
      // store.commit(MutationTypes.SET_VIEWPORT_WIDTH, data.templateList[0].backGroundWidth)
      // store.commit(MutationTypes.SET_VIEWPORT_HEIGHT, data.templateList[0].backGroundHeight)
    }

    //主函数
    var main = () => {

      //添加一个div容器，用于
      // console.log('cupDiv',cup.value)
      container = document.createElement('div')
      // document.body.appendChild(container);

      //1、创建渲染器
      //渲染
      //antialias:true增加抗锯齿效果
      renderer = new THREE.WebGLRenderer({antialias: true})
      renderer.setPixelRatio( window.devicePixelRatio );
      //设置窗口背景颜色
      renderer.setClearColor(new THREE.Color('#b6c3c7'))
      //设置窗口尺寸
      renderer.setSize(window.innerWidth, window.innerHeight)

      renderer.shadowMap.enabled = false;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      renderer.outputEncoding = THREE.sRGBEncoding;
      //将renderer关联到container，这个过程类似于获取canvas元素
      // container.appendChild(renderer.domElement);


      var divName: any = document.getElementById('cupshow')
      divName.appendChild(renderer.domElement)


      //2、创建场景和摄像头
      //创建一个新场景
      scene = new THREE.Scene()
      //添加一个透视相机
      camera = new THREE.PerspectiveCamera(35,
          window.innerWidth / window.innerHeight, 1, 1000)
      camera.position.set( 160, 40, 10 );

      // camera.lookAt(new THREE.Vector3(0, 0, 0))//让相机指向原点

      //3、增加光源
      //给场景添加光源
      //自然光
      var ambientLight = new THREE.AmbientLight(0x606060)
      scene.add(ambientLight)


      var spotLightTop = new THREE.SpotLight( 0xffffff, 1 );
      spotLightTop.position.set( 0, 100, 0 );
      spotLightTop.angle = Math.PI / 4;
      spotLightTop.penumbra = 0.1;
      spotLightTop.decay = 2;
      spotLightTop.distance = 200;
      scene.add( spotLightTop );

      var spotLightBottom = new THREE.SpotLight( 0xffffff, 1 );
      spotLightBottom.position.set( 0, -250, 0 );
      spotLightBottom.angle = Math.PI / 4;
      spotLightBottom.penumbra = 0.1;
      spotLightBottom.decay = 2;
      spotLightBottom.distance = 200;
      //scene.add( spotLightBottom );
      // lightHelper = new THREE.SpotLightHelper( spotLightBottom );
      // scene.add( lightHelper );

      lightHolder = new THREE.Group();

      var spotLight1 = new THREE.SpotLight( 0xffffff, 1 );
      spotLight1.position.set( 100, -20, 0 );
      spotLight1.angle = Math.PI / 4;
      spotLight1.penumbra = 0.1;
      spotLight1.decay = 2;
      spotLight1.distance = 200;
      spotLight1.castShadow = false;
      lightHolder.add(spotLight1);


      var spotLight2 = new THREE.SpotLight( 0xffffff, 1 );
      spotLight2.position.set( -20, -20, 100 );
      spotLight2.angle = Math.PI / 4;
      spotLight2.penumbra = 0.1;
      spotLight2.decay = 2;
      spotLight2.distance = 200;
      spotLight2.castShadow = false;
      lightHolder.add( spotLight2 );

      var spotLight3 = new THREE.SpotLight(0xffffff, 1);
      spotLight3.position.set(-100, -20, 0);
      spotLight3.angle = Math.PI / 4;
      spotLight3.penumbra = 0.1;
      spotLight3.decay = 2;
      spotLight3.distance = 200;
      spotLight3.castShadow = false;
      lightHolder.add(spotLight3);

      var spotLight4 = new THREE.SpotLight(0xffffff, 1);
      spotLight4.position.set(20, -20, -100);
      spotLight4.angle = Math.PI / 4;
      spotLight4.penumbra = 0.1;
      spotLight4.decay = 2;
      spotLight4.distance = 200;
      spotLight4.castShadow = false;
      lightHolder.add(spotLight4);

      scene.add(lightHolder);

      //4、创建几何体、材质
      initModel()
      // run();

      controls = new OrbitControls(camera, renderer.domElement)//创建控件对象
      // controls.addEventListener('change', render)//监听鼠标、键盘事件
      // controls.listenToKeyEvents( window );
      controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      controls.dampingFactor = 0.05;
      //
      controls.screenSpacePanning = false;
      //
      controls.minDistance = 100;
      controls.maxDistance = 1000;
      controls.enablePan = true;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 10;
      // document.addEventListener('mousedown', onDocumentMouseDown, false);
      window.addEventListener('resize', onWindowResize, false);
      render()
    }

    //创建圆柱体
    function initModel() {
      // console.log('执行了')
      var geometry = new THREE.TorusGeometry(papercup.upSize + 0.1, 1.3, 16, 160)
      var materialW = new THREE.MeshLambertMaterial({color: 0xFFFFFF})
      var torus = new THREE.Mesh(geometry, materialW)
      //设置几何体的位置（x,y,z）
      torus.position.set(0, (papercup.height / 2 - 20) - 0.7, 0)
      torus.rotateX(Math.PI / 2)
      scene.add(torus)

      //mPaperMaterial = new THREE.MeshLambertMaterial({color: 0xFFFFFF, opacity: 0.98, transparent: false, visible: true});
      mPaperMaterial = new THREE.MeshStandardMaterial({roughness:0.5, metalness:0.1});
      mPaperMaterial.side = 2;
      mPaperMaterial.transparent = false;
      var m2 = new THREE.MeshLambertMaterial({color: 0xFFFFFF, opacity: 1, transparent: false, visible: true, side:THREE.DoubleSide});   //材料
      var materials = [mPaperMaterial, null, null];

      //创建圆柱体
      var cylinder = new THREE.CylinderGeometry(papercup.upSize, papercup.downSize, papercup.height, 40, 40, false)
      paperCube = new THREE.Mesh(cylinder, materials);
      //设置几何体的位置（x,y,z）
      paperCube.position.set(0, -20, 0);
      scene.add(paperCube);

      var m3: any = new THREE.MeshLambertMaterial({color: 0xFFFFFF, opacity: 1, transparent: false, visible: true, side: THREE.DoubleSide});   //材料
      var m4: any = new THREE.MeshLambertMaterial({color: 0xFFFFFF, opacity: 1, transparent: false, visible: true, side: THREE.DoubleSide});   //材料

      var cylinder2 = new THREE.CylinderGeometry(papercup.downSize, papercup.downSize, 3, 40, 40, true);
      var materials2 = [m3, null, null];
      var paperCube2 = new THREE.Mesh(cylinder2, materials2);
      //设置几何体的位置（x,y,z）
      paperCube2.position.set(0, (papercup.height / 2 - 20 - papercup.height + 1.6), 0);
      paperCube2.castShadow = false;
      scene.add(paperCube2);

      var cylinder3 = new THREE.CylinderGeometry(papercup.upSize - 0.9, papercup.downSize, papercup.height - 3, 40, 40, false);
      var materials3 = [m3, null, m4];
      var paperCube3 = new THREE.Mesh(cylinder3, materials3);
      //设置几何体的位置（x,y,z）
      paperCube3.position.set(0, -19, 0);
      paperCube3.castShadow = false;
      scene.add(paperCube3);

      camera.position.z = 0;
      camera.position.x = 350;

      // console.log(imgUrl.value)
      loader.load(imgUrl, function(t:any)//图片地址可使用本地,同根目录下文件夹即可
          {
            mPaperMaterial.map = t
            t.needsUpdate = true
            mPaperMaterial.needsUpdate = true
            paperCube.needsUpdate = true
          },
          undefined,
          // onError回调
          function(err:any) {
            console.error('An error happened.')
          })
    }

    //渲染
    function render() {
      //记录当前时间
      // nowTime = Date.now()
      // if (nowTime - lastTime > diffTime) {
      //   lastTime = nowTime
      //   renderer.render(scene, camera);
      // }
      renderer.render(scene, camera);
      controls.update();
      lightHolder.quaternion.copy(camera.quaternion);
      requestAnimationFrame(render);
    }


    var onWindowResize = ($event: any) => {
      windowHalfX = window.innerWidth / 2;
      windowHalfY = window.innerHeight / 2;
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
    }

    // main();
    return {
      main,
      cup,
      htmlString,
      cupShow,
    }
  },
})
</script>

<style lang="scss" scoped>
.pptist-screen {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #111;
}

.slide-list {
  background: #1d1d1d;
  position: relative;
  width: 100%;
  height: 100%;
}

.slide-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.current {
    z-index: 2;
  }

  &.hide {
    opacity: 0;
  }

  &.turning-mode-no {
    &.before {
      transform: translateY(-100%);
    }

    &.after {
      transform: translateY(100%);
    }
  }

  &.turning-mode-fade {
    transition: opacity .75s;

    &.before {
      pointer-events: none;
      opacity: 0;
    }

    &.after {
      pointer-events: none;
      opacity: 0;
    }
  }

  &.turning-mode-slideX {
    transition: transform .35s;

    &.before {
      transform: translateX(-100%);
    }

    &.after {
      transform: translateX(100%);
    }
  }

  &.turning-mode-slideY {
    transition: transform .35s;

    &.before {
      transform: translateY(-100%);
    }

    &.after {
      transform: translateY(100%);
    }
  }
}

.slide-content {
  //background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools {
  position: fixed;
  bottom: 8px;
  left: 8px;
  font-size: 25px;
  color: #666;
  z-index: 10;
  cursor: pointer;
}

.tool-btn {
  opacity: .35;

  &:hover {
    opacity: .9;
  }

  & + .tool-btn {
    margin-left: 8px;
  }
}

.page-number {
  position: fixed;
  bottom: 8px;
  right: 8px;
  padding: 8px 12px;
  color: #666;
  background-color: #eee;
  border-radius: $borderRadius;
  z-index: 10;
  cursor: pointer;
}
</style>