
import {ref, onMounted, computed} from 'vue'
import {useStore} from '@/store'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {getBoxConfigJson} from '@/api/project'
import {SceneUtils} from 'three/examples/jsm/utils/SceneUtils.js'
import TWEEN from '@tweenjs/tween.js'
import {getQueryObject} from '@/utils/utils'
import axios from 'axios'

export default {
  name: 'reticuleShare',
  props:{
    params:{
      type:Object
    }
  },
  setup(props:any) {
    // //自由旋转
    function rotateAroundWorldAxis(obj: any, point: any, axis: any, angle: any) {
      var q1 = new THREE.Quaternion()

      q1.setFromAxisAngle(axis, angle)

      obj.quaternion.multiplyQuaternions(q1, obj.quaternion)

      obj.position.sub(point)
      obj.position.applyQuaternion(q1)
      obj.position.add(point)

      return obj
    }

    function deepClone(target: any) {
      // 定义一个变量
      let result: any
      // 如果当前需要深拷贝的是一个对象的话
      if (typeof target === 'object') {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
          result = [] // 将result赋值为一个数组，并且执行遍历
          for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(deepClone(target[i]))
          }
          // 判断如果当前的值是null的话；直接赋值为null
        }
        else if (target === null) {
          result = null
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
        }
        else if (target.constructor === RegExp) {
          result = target
        }
        else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {}
          for (let i in target) {
            result[i] = deepClone(target[i])
          }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
      }
      else {
        result = target
      }
      // 返回最终结果
      return result
    }

    let container = ref(null)
    var computeGroupCenter = (function() {
      var childBox = new THREE.Box3()
      var groupBox = new THREE.Box3()
      var invMatrixWorld = new THREE.Matrix4()

      return function(group: any, optionalTarget: any) {
        if (!optionalTarget) optionalTarget = new THREE.Vector3()

        group.traverse(function(child: any) {
          if (child instanceof THREE.Mesh) {
            if (!child.geometry.boundingBox) {
              child.geometry.computeBoundingBox()
              childBox.copy(child.geometry.boundingBox)
              child.updateMatrixWorld(true)
              childBox.applyMatrix4(child.matrixWorld)
              groupBox.min.min(childBox.min)
              groupBox.max.max(childBox.max)
            }
          }
        })

        // All computations are in world space
        // But the group might not be in world space
        group.matrixWorld.getInverse(invMatrixWorld)
        groupBox.applyMatrix4(invMatrixWorld)

        groupBox.getCenter(optionalTarget)
        return optionalTarget
      }
    })()

    var SCREEN_WIDTH = window.innerWidth, SCREEN_HEIGHT = window.innerHeight //
    var camera: any, scene: any, renderer: any
    const loader = new THREE.TextureLoader()
    var orbitControl
    var texture: any
    var planeGroup = new THREE.Group()
    var planeTweens: any[] = []
    var rotateAxesX = new THREE.Vector3(1, 0, 0)
    var rotateAxesY = new THREE.Vector3(0, 1, 0)
    var rotateAxesZ = new THREE.Vector3(0, 0, 1)
    var lightHolders: any[] = []

    onMounted(async () => {
      await  getImage()
      main()
    })

    let imgUrl:any
    // 获取照片
    const getImage= async ()=>{
      let params:any = getQueryObject(window.location.href)
      await axios.get(`apis/filesup/templateThumb/${params.projectGUID}_${params.pageIndex}.bmg`).then(res =>{
        // console.log(res)
        imgUrl=res.data.replace(/[\r\n]/g,"");
      })
    }
    //主函数
    function main() {
      //添加一个div容器，用于
      var container: any = document.getElementById('container')

      //1、创建渲染器
      //渲染
      //antialias:true增加抗锯齿效果
      renderer = new THREE.WebGLRenderer({antialias: true, logarithmicDepthBuffer: true})
      renderer.setPixelRatio(window.devicePixelRatio)
      //设置窗口背景颜色为黑
      renderer.setClearColor(new THREE.Color('#b6c3c7'))
      //设置窗口尺寸
      renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT)
      //将renderer关联到container，这个过程类似于获取canvas元素=
      container.appendChild(renderer.domElement)

      renderer.shadowMap.enabled = false
      renderer.shadowMap.type = THREE.PCFSoftShadowMap
      renderer.outputEncoding = THREE.sRGBEncoding
      renderer.localClippingEnabled = true
      //2、创建场景和摄像头
      //创建一个新场景
      scene = new THREE.Scene()
      //添加一个透视相机
      camera = new THREE.PerspectiveCamera(30, SCREEN_WIDTH / SCREEN_HEIGHT, 0.1, 5000)
      // camera.position.set(160, 40, 10);
      camera.position.z = 1200
      camera.position.y = 400
      camera.position.x = 300

      //3、增加光源
      //给场景添加光源
      //自然光
      var ambientLight = new THREE.AmbientLight(0x606060)
      scene.add(ambientLight)

      const store = useStore()
      const handbagWidth = props.params.width
      const handbagHeight = props.params.height
      const handbagThickness = props.params.depth
      var data = {
        width: handbagWidth,
        height: handbagHeight,
        depth: handbagThickness
      }
      axios({url:'apis/business/getBoxConfigJson',method:'post',params:data}).then(res => {
        var objGroup = JSON.parse(res.data.data)
        // //4、创建几何体、材质
        // texture = loader.load(imgUrl.value);
        loader.load(imgUrl, function(t: any) {
              texture = t
              texture.wrapS = texture.wrapT = THREE.RepeatWrapping
              texture.repeat.set(1 / objGroup.totalX, 1 / objGroup.totalY)
              initModelByObjJson(objGroup)
              // console.log(planeTweens)
              planeTweens[0].start()
            },
            undefined,
            // onError回调
            function(err) {
              console.error('An error happened.')
            }
        )
        // texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        // texture.repeat.set(1 / res.data.totalX, 1 / res.data.totalY);
        // initModelByObjJson(res.data);
        //
        // planeTweens[0].start();
      })


      orbitControl = new OrbitControls(camera, renderer.domElement)//创建控件对象
      // controls.listenToKeyEvents( window );
      orbitControl.enableDamping = true // an animation loop is required when either damping or auto-rotation are enabled
      orbitControl.dampingFactor = 0.05
      //
      orbitControl.screenSpacePanning = false

      orbitControl.minDistance = 100
      orbitControl.maxDistance = 2000
      orbitControl.enablePan = true
      orbitControl.enabled = true
      orbitControl.autoRotate = false
      orbitControl.autoRotateSpeed = 10


      window.addEventListener('resize', onWindowResize, false)
      // console.log(window.innerHeight)
      render()
    }

    function initModelByObjJson(objGroup: any) {
      // console.log(JSON.parse(objGroup))
      var newObjGroup = objGroup
      //显示坐标
      var axes = new THREE.AxesHelper(100)
      // scene.add(axes);

      //显示坐标格
      var gridHelper = new THREE.GridHelper(100, 100)
      gridHelper.rotation.x = Math.PI / 2
      // scene.add( gridHelper );

      for (let i = 0; i < newObjGroup.lights.length; i++) {
        var lights = newObjGroup.lights[i]
        var lightholder = new THREE.Group()
        for (let j = 0; j < lights.length; j++) {
          var light = lights[j]

          var spotLight = new THREE.SpotLight(0xffffff, 1)
          spotLight.position.set(light.x, light.y, light.z)
          spotLight.angle = Math.PI / 4
          spotLight.penumbra = 0.1
          spotLight.decay = 2
          spotLight.distance = light.distance
          spotLight.castShadow = false

          // var lightHelper = new THREE.SpotLightHelper(spotLight);
          // scene.add(lightHelper)

          if (lights.length > 1) {
            lightholder.add(spotLight)
          }
          else {
            scene.add(spotLight)
          }
        }
        if (lights.length > 1) {
          lightHolders.push(lightholder)
          scene.add(lightholder)
        }
      }

      var materialFront = new THREE.MeshStandardMaterial({
        opacity: 1,
        // flatShading: true,
        transparent: false,
        side: THREE.FrontSide,
        map: texture,
        roughness: 0.5,
        dithering: true,
        aoMapIntensity: 1,
        precision: 'highp',
        // renderOrder: 2,
        metalness: 0.2
      })
      var materialBack = new THREE.MeshStandardMaterial({
        color: 0xFFFFFF,
        side: THREE.BackSide,
        transparent: false,
        opacity: 1,
        dithering: true,
        precision: 'highp',
        // depthWrite: true,
        // depthTest: false,
        roughness: 0.5, metalness: 0.1
      })
      var materialsArray = [materialFront, materialBack]

      for (let i = 0; i < newObjGroup.faces.length; i++) {
        var planeFace = newObjGroup.faces[i]
        var planeShape = new THREE.Shape()
        planeShape.moveTo(planeFace.x, planeFace.y)
        planeShape.setFromPoints([
          new THREE.Vector2(planeFace.x, planeFace.y),
          new THREE.Vector2(planeFace.x + planeFace.w, planeFace.y),
          new THREE.Vector2(planeFace.x + planeFace.w, planeFace.y + planeFace.h),
          new THREE.Vector2(planeFace.x, planeFace.y + planeFace.h),
        ])

        if ((planeFace.holes) && (planeFace.holes.length > 0)) {
          for (let j = 0; j < planeFace.holes.length; j++) {
            var shapeHole = planeFace.holes[j]
            var holeShape = new THREE.Shape()
            holeShape.ellipse(shapeHole.x, shapeHole.y, shapeHole.xRadius, shapeHole.yRadius, 0, Math.PI * 2, true, 0)
            planeShape.holes.push(holeShape)
          }
        }
        var planeGeometry = new THREE.ShapeGeometry(planeShape, 24)
        planeGeometry.computeBoundingBox()
        var planeMesh = SceneUtils.createMultiMaterialObject(planeGeometry, materialsArray)
        planeMesh.name = planeFace.name
        // planeMesh.needsUpdate = true;
        if (planeFace.z) {
          planeMesh.translateZ(planeFace.z)
        }
        if (planeFace.renderOrder) {
          planeMesh.renderOrder = planeFace.renderOrder
        }
        planeGroup.add(planeMesh)
      }
      planeGroup.translateX(newObjGroup.translateX * newObjGroup.scale)
      planeGroup.translateY(newObjGroup.translateY * newObjGroup.scale)
      planeGroup.scale.set(newObjGroup.scale, newObjGroup.scale, newObjGroup.scale)
      scene.add(planeGroup)


      for (let i = 0; i < newObjGroup.animate.length; i++) {
        var animateGroup = newObjGroup.animate[i]
        var iRepeatCount = animateGroup.repeatCount
        for (let j = 0; j < iRepeatCount + 1; j++) {
          var animatParams = deepClone(animateGroup)
          animatParams.actionCount = 0
          animatParams.value = 1
          var tween = new TWEEN.Tween(animatParams)
              // .delay(5000) // 等待100ms
              .to({value: 1}, 10) // 1s时间，x到200
              .onUpdate(function(params: any) {
                // console.log(params);
                params.actionCount += 1
                if (params.actionCount <= 1) {
                  for (let k = 0; k < params.group.length; k++) {
                    var animate = params.group[k]
                    var o = scene.getObjectByName(animate.name)
                    if (o) {
                      var rotateAxes = rotateAxesX
                      if (animate.axes === 'x') {
                        rotateAxes = rotateAxesX
                      }
                      else if (animate.axes === 'y') {
                        rotateAxes = rotateAxesY
                      }
                      else if (animate.axes === 'z') {
                        rotateAxes = rotateAxesZ
                      }
                      var angle = -1
                      if (animate.path === 'c') {
                        angle = -1
                      }
                      else {
                        angle = 1
                      }
                      rotateAroundWorldAxis(o, animate.vector, rotateAxes, THREE.MathUtils.degToRad(angle))
                      o.needsUpdate = true
                    }
                  }
                }

              })
          planeTweens.push(tween)
        }

      }
      for (let i = 0; i < planeTweens.length - 1; i++) {
        planeTweens[i].chain(planeTweens[i + 1])
      }
    }

    function render() {
      TWEEN.update()
      renderer.render(scene, camera)
      requestAnimationFrame(render)

      if (lightHolders.length > 0) {
        for (let i = 0; i < lightHolders.length; i++) {
          lightHolders[i].quaternion.copy(camera.quaternion)
        }
      }

    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize( window.innerWidth, window.innerHeight)
    }

    return {
      container,
      main,
      getImage,
    }

  }
}
