
import {ref, onMounted, computed} from 'vue'
import {MutationTypes, useStore} from '@/store'
import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {getProject, getProjectByLine, getTemplateThumbByLine} from '@/api/project'
import {message} from 'ant-design-vue'
import {getQueryObject} from '@/utils/utils'
import axios from 'axios'
import CupShare from '@/views/System/share/cupShare.vue'
import reticuleShare from '@/views/System/share/reticuleShare.vue'

export default ({
  name: 'cupShow',
  components:{
    reticuleShare,
    CupShare
  },
  setup() {

    let params  = ref({})
    onMounted(async () => {
      params.value = getQueryObject(window.location.href)
    })

    return{
      params
    }
  },
})
